import React, { useState, useEffect } from 'react';
import {
  func, string, node, bool,
} from 'prop-types';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';

import { useDataLoadContext } from 'contexts/data-load-context';

import {
  Grid,
  Card,
  Hidden,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Collapse,
  Button,
  Box,
  Typography,
} from '@material-ui/core';

import useStyles from './styles';

// OMG fix this

const BaseSubscription = ({
  level = '1',
  title, subheader = '',
  smTitle = '', smSubTitle = '',
  avatar, onCardClick,
  description = '',
  content = null,
  collapsed = false,
  looking = false,
  onExpand = () => {},
  expanded = false,
  single = false,
}) => {
  const styles = useStyles();

  const [loading, setLoading] = useState(false);

  const { scriptsLoading } = useDataLoadContext();

  useEffect(() => {
    setLoading(scriptsLoading);
    // eslint-disable-next-line
  }, [scriptsLoading]);

  const expand = () => {
    onExpand();
  };

  if (single) {
    return (
      <>
      <Hidden xsDown>
      <Box
        border={1}
        borderRadius={4}
        >
        {loading ? (
          <Skeleton animation="wave" height={75} style={{ margin: 0, padding: 0 }} />
        ) : (
          <Card
            className={classNames(styles.card, styles.noPointer)}>
            <CardHeader
              avatar={avatar}
              title={smTitle}
              subheader={smSubTitle}
              aria-label={smTitle}
            />
          </Card>
        )}
      </Box>
      </Hidden>
      <Hidden smUp>
        <div className={styles.smallAvatar}>
          {avatar}
        </div>
      </Hidden>
    </>
    );
  }
  return collapsed ? (
    <>
    {loading ? (
      <Skeleton animation="wave" height={110} style={{ margin: 0, padding: 0 }} />
    ) : (
      <Box
        border={1}
        className={looking ? styles.activeCardContainer : styles.cardContainer}
        borderRadius={1}
        >
        <Card className={styles.card} onClick={onCardClick}>
          <CardHeader
            className={styles.pointer}
            avatar={avatar}
            title={smTitle}
            subheader={smSubTitle}
            aria-label="select this subscription"
          />
        </Card>
      </Box>
    )}
  </>
  ) : (
    <>
    {loading ? (
      <>
        <Hidden xsDown>
          <Skeleton animation="wave" height={200} />
        </Hidden>
        <Hidden smUp>
          <Skeleton animation="wave" height={150} />
        </Hidden>
      </>
    ) : (
    <Card>
      <CardHeader
        avatar={avatar}
        title={title}
        onClick={expand}
        subheader={subheader}
        className={styles.pointer}
        aria-label="click to expand for more information"
        action={
          <IconButton
            className={classNames(styles.expand, {
              [styles.expandOpen]: expanded,
            })}
            onClick={expand}
            aria-expanded={expanded}
            aria-label="show more information"
          >
            <FontAwesomeIcon icon={faAngleUp} size="lg" className={styles.infoIcon}/>
          </IconButton>
      }
      />
        <CardContent className={styles.nomargin}>
          {description && (
            <Typography className={styles.marginLeft2} variant="body1">
             {description}
           </Typography>
          )}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
              {content}
              </CardContent>
            </Collapse>
        </CardContent>

      <CardActions disableSpacing>
        <Grid spacing={1}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Button
              color="primary"
              fullWidth
              variant={expanded ? 'contained' : 'outlined'}
              onClick={async () => {
                if (expanded) {
                  onCardClick();
                } else {
                  onExpand();
                }
              }}
              className={expanded ? styles.greenbutton : styles.noColorButton}
              >
              {expanded ? 'GET STARTED' : 'MORE INFORMATION' }
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
    )}
</>
  );
};

BaseSubscription.propTypes = {
  level: string,
  avatar: node.isRequired,
  onCardClick: func.isRequired,
  title: string.isRequired,
  subheader: string,
  smTitle: string.isRequired,
  smSubTitle: string,
  description: string,
  content: node,
  collapsed: bool,
  looking: bool,
  onExpand: func,
  expanded: bool,
  single: bool,
  state: string,
  periodEnd: string,
};

export default BaseSubscription;
